// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Rest from "../Rest.res.js";
import * as User from "../../models/User.res.js";
import * as SignUpData from "../../bundles/client/sign-up/SignUpData.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";

var decoder = Json_Decode$JsonCombinators.object(function (field) {
      return {
              invitationPending: field.optional("invitationPending", Json_Decode$JsonCombinators.bool),
              user: field.required("user", User.decoder)
            };
    });

var UserWithShowInvitation = {
  decoder: decoder
};

var decoder$1 = Json_Decode$JsonCombinators.object(function (field) {
      return {
              message: field.required("message", Json_Decode$JsonCombinators.string),
              errorCode: field.optional("errorCode", Json_Decode$JsonCombinators.string)
            };
    });

var Err = {
  decoder: decoder$1
};

var toJson = SignUpData.Input.toJson;

var Req = {
  toJson: toJson
};

var decoder$2 = Json_Decode$JsonCombinators.map(decoder, (function (data) {
        return {
                TAG: "UserWithShowInvitation",
                _0: data
              };
      }));

var Res = {
  decoder: decoder$2
};

function exec(input, form) {
  return Rest.$$fetch("/users/sign_up", {
              NAME: "Post",
              VAL: toJson(input, form)
            }, {
              NAME: "Json",
              VAL: decoder$2
            }, {
              NAME: "Message",
              VAL: decoder$1
            }, undefined);
}

export {
  UserWithShowInvitation ,
  Err ,
  Req ,
  Res ,
  exec ,
}
/* decoder Not a pure module */
